import type { ChordModifier, EventType, TablatureEvent } from "@common/tablature-event";
import { arrayReplace } from "@common/utils/array-replace";
import type { TablatureDTO } from "../../../common/dtos/song.dto";
import type { NoteDTO, NoteModifier } from "../../../common/note";
import { isChordEvent, isEmptyEvent } from "../../../common/tablature-events-utils";
import type { TablaturePosition } from "../../interfaces/tablature-position";
import { resizeArray } from "../../utils/resize-array";

export type Stave = {
    lineCount: number;
};

export class Tablature {
    public events: Array<TablatureEvent | undefined>;
    public stave: Stave;

    constructor(dto: { stave: Stave; events: Array<TablatureEvent | undefined> }) {
        this.events = dto.events;
        this.stave = dto.stave;
    }

    public get lineCount(): number {
        return this.stave.lineCount;
    }

    public get eventsCount(): number {
        return this.events.length;
    }

    public trimmedEventsCount(): number {
        for (let i = this.eventsCount - 1; i > 0; i--) {
            const event = this.events[i];
            if (this.eventHasData(event)) {
                return i;
            }
        }
        return 0;
    }

    public updateStrings(strings: number): void {
        this.stave.lineCount = strings;
        this.sanitize();
    }

    public get(index: number): TablatureEvent | undefined {
        return this.events[index];
    }

    public getAll(): Array<TablatureEvent | undefined> {
        return this.events;
    }

    public getAnnotation(index: number): string | undefined {
        return this.get(index)?.annotation;
    }

    public getEvents(startIndex: number, endIndex: number): Array<TablatureEvent | undefined> {
        return this.events.slice(startIndex, endIndex + 1);
    }

    public getNotes(eventIndex: number): Array<NoteDTO | undefined> | undefined {
        const event = this.events[eventIndex];
        if (!isChordEvent(event)) {
            return undefined;
        }
        const chord = event.data;
        if (Array.isArray(chord.notes)) {
            return chord.notes;
        }
    }

    public getNote(position: TablaturePosition): NoteDTO | undefined {
        const notes = this.getNotes(position.eventIndex);
        return notes ? notes[position.stringIndex] : undefined;
    }

    public getNoteModifiers(position: TablaturePosition): NoteModifier[] {
        const tablatureEvent = this.events[position.eventIndex];
        if (!isChordEvent(tablatureEvent)) {
            return [];
        }

        const chord = tablatureEvent.data;
        const note = chord.notes[position.stringIndex];
        return note?.modifiers || [];
    }

    public chordHasModifier(index: number, type: ChordModifier): boolean {
        const tablatureEvent = this.events[index];
        if (!isChordEvent(tablatureEvent)) {
            return false;
        }
        const chord = tablatureEvent.data;
        const modifiers = chord.modifiers || [];
        return modifiers.includes(type);
    }

    public getEventType(eventIndex: number): EventType {
        const event = this.events[eventIndex];
        return event?.type;
    }

    public sanitize(): void {
        // Remove events of size bigger than needed
        const cleanEvents: TablatureEvent[] = [];
        for (let i = 0; i < this.events.length; i++) {
            let event = this.events[i];
            event = this.sanitizeAnnotation(event);

            if (isChordEvent(event)) {
                event.data.notes = arrayReplace(
                    resizeArray(event.data.notes, this.stave.lineCount, undefined),
                    null,
                    undefined
                );
            }

            if (this.eventHasData(event)) {
                cleanEvents[i] = event;
            }
        }
        this.events = cleanEvents;
    }

    public toDTO(): TablatureDTO {
        return JSON.parse(
            JSON.stringify({
                events: this.events,
                stave: this.stave,
            })
        );
    }

    private eventHasData(event: TablatureEvent | undefined): event is TablatureEvent {
        return !isEmptyEvent(event) || typeof event?.annotation === "string";
    }

    private sanitizeAnnotation(event: TablatureEvent | undefined): TablatureEvent | undefined {
        if (!event) {
            return event;
        }

        if (typeof event.annotation === "string") {
            event.annotation = event.annotation.trim();
            if (event.annotation === "") {
                event.annotation = undefined;
            }
        } else {
            event.annotation = undefined;
        }
        return event;
    }
}
