import { openDB, type IDBPDatabase } from "idb";
import type { SongDTO } from "../../common/dtos/song.dto";
import type { Song } from "../models/song/song";
import { parseSongDTO } from "../models/song/song-parser";
import { LogApi } from "./log";

export const SONGS_STORE = "songs-test";

const DB_VERSION = 1;

export type LocalSongRecord = {
    data: string;
    id: string;
};

export class LocalSongsApi {
    private _db: Promise<IDBPDatabase>;
    private connecting = false;

    constructor() {
        this._db = this.connect();
    }

    public async getAll(): Promise<Song[]> {
        const db = await this.getDb();
        const rawSongs: LocalSongRecord[] = await db.getAll(SONGS_STORE);

        return rawSongs.map((s) => this.songRecordToSong(s));
    }

    public async get(id: string): Promise<Song | undefined> {
        const db = await this.getDb();
        const rawSong: LocalSongRecord | undefined = await db.get(SONGS_STORE, id);
        if (!rawSong) {
            return undefined;
        }

        return this.songRecordToSong(rawSong);
    }

    public async has(id: string): Promise<boolean> {
        const db = await this.getDb();
        const rawSong: LocalSongRecord | undefined = await db.get(SONGS_STORE, id);
        return Boolean(rawSong);
    }

    public async count(): Promise<number> {
        const db = await this.getDb();
        return db.count(SONGS_STORE);
    }

    public async setSong(id: string, song: SongDTO): Promise<void> {
        const db = await this.getDb();
        const songRecord = this.createSongRecord(id, song);
        await db.put(SONGS_STORE, songRecord, id);
    }

    public async setSongs(songs: Song[]): Promise<void> {
        const db = await this.getDb();

        const tx = db.transaction(SONGS_STORE, "readwrite");
        const store = tx.objectStore(SONGS_STORE);
        for (const song of songs) {
            const songRecord = this.createSongRecord(song.id, song.toDTO());
            store.put(songRecord, songRecord.id);
        }
        await tx.done;
    }

    public async delete(id: string): Promise<void> {
        const db = await this.getDb();
        await db.delete(SONGS_STORE, id);
    }

    public async clear(): Promise<void> {
        const db = await this.getDb();
        await db.clear(SONGS_STORE);
    }

    private getDb(): Promise<IDBPDatabase> {
        return this._db;
    }

    private createSongRecord(id: string, song: SongDTO): LocalSongRecord {
        return {
            data: JSON.stringify(song),
            id,
        };
    }

    private songRecordToSong(record: LocalSongRecord): Song {
        const songDTO = JSON.parse(record.data) as SongDTO;
        return parseSongDTO(record.id, songDTO);
    }

    private async connect(): Promise<IDBPDatabase> {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const t = this;
        this.connecting = true;
        const db = await openDB("my-guitar-tabs", DB_VERSION, {
            upgrade(db, _oldVersion, _newVersion, _transaction, _event) {
                db.createObjectStore(SONGS_STORE);
            },
            // blocked(currentVersion, blockedVersion, event) {
            //     // …
            // },
            // blocking(currentVersion, blockedVersion, event) {
            //     // …
            // },
            terminated() {
                const logApi = new LogApi();
                logApi.error("IndexedDB terminated");
                if (!t.connecting) {
                    // DB terminated unexpectedly after connect, try reconnect
                    console.warn("IndexedDB terminated, attempting reconnect");
                    t._db = t.connect();
                } else {
                    throw new Error("Error reconnecting to IndexedDB");
                }
            },
        });
        this.connecting = false;
        return db;
    }
}
