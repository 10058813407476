<template>
    <nav class="header navbar navbar-fixed-top">
        <div class="container container-fluid">
            <button
                class="btn bi bi-arrow-left back"
                :title="$t('back')"
                @click="onNavigateBack"
                @keydown.enter="onNavigateBack"
                tabindex="0"></button>

            <span class="navbar-text">{{ $t("exportToPDFTitle") }}</span>
        </div>
    </nav>
</template>

<script lang="ts">
import UserDropdownMenu from "@components/user-dropdown-menu/user-dropdown-menu.vue";
import { defineComponent, PropType } from "vue";
import type { Song } from "../../../models/song/song";

export default defineComponent({
    name: "pdf-header",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    components: {
        "user-dropdown-menu": UserDropdownMenu,
    },
    methods: {
        onNavigateBack(): void {
            this.$router.replace({
                name: "song",
                query: { song: this.song.id },
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    background-color: var(--background-color-primary);
    border-bottom: solid 1px var(--gray-300);

    .container {
        column-gap: 25px;
        flex-wrap: nowrap;
        position: relative;
    }
}
</style>
