import { DEFAULT_INSTRUMENT, MAX_SUPPORTED_SONG_DTO_VERSION } from "../../../common/constants";
import type { SongDTO } from "../../../common/dtos/song.dto";
import type { SongTags } from "../../../common/song-tags";
import { Song } from "./song";

// NOTE: The input may also contain null instead of undefined in events
export type ParserInput = Omit<SongDTO, "version" | "_v"> & { _v: number | undefined };

type ParseErrorReason = "unsupported-version";

export class SongParseError extends Error {
    public reason: ParseErrorReason;

    constructor(reason: ParseErrorReason, msg: string) {
        super(msg);
        this.name = "SongParseError";
        this.reason = reason;
    }
}

export function parseSongDTO(id: string, originalSong: ParserInput): Song {
    // Any parsing regarding multiple versions must be done here
    const dtoVersion = originalSong._v ?? 1;

    if (dtoVersion > MAX_SUPPORTED_SONG_DTO_VERSION) {
        throw new SongParseError("unsupported-version", `Unsupported song version: ${dtoVersion}`);
    }

    originalSong.metadata.visibility = originalSong.metadata.visibility ?? "private";
    originalSong.metadata.labels = originalSong.metadata.labels ?? [];

    originalSong.tags = parseTags(originalSong.tags);

    return new Song({
        ...originalSong,
        id,
    }).sanitize();
}

function parseTags(originalTags: SongTags): SongTags {
    if (!originalTags.instrument) {
        originalTags.instrument = DEFAULT_INSTRUMENT;
    }

    return originalTags;
}
