import type { FirebaseApp } from "firebase/app";
import type { User } from "firebase/auth";
import type { DocumentReference, Timestamp } from "firebase/firestore";
import {
    collection,
    deleteDoc,
    doc,
    getDocFromServer,
    getDocsFromServer,
    getFirestore,
    type Firestore,
} from "firebase/firestore";
import type { Functions } from "firebase/functions";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import type { FirestoreSong } from "../../common/dtos/firestore.dto";
import type { SongDTO } from "../../common/dtos/song.dto";
import type { SongVisibility } from "../../common/song-metadata";
import type { Song } from "../models/song/song";
import { parseSongDTO, SongParseError } from "../models/song/song-parser";
import { nullToUndefined } from "../utils/null-to-undefined";
import { omitFields } from "../utils/utils";
import { api } from "./api";
import type { AuthApi } from "./auth-api";
import { ApiConfig } from "./config";
import { LogApi } from "./log";

type FirebaseSong = SongDTO & { id: string };

export class RemoteSongsApi {
    private db: Firestore;
    private auth: AuthApi;
    private functions: Functions;

    constructor(auth: AuthApi, app: FirebaseApp) {
        this.auth = auth;
        this.db = getFirestore(app);
        this.functions = getFunctions(app);

        if (ApiConfig.emulateFunctions) {
            if (ApiConfig.releaseStage === "development") {
                console.warn("Using Functions emulator");
                connectFunctionsEmulator(this.functions, "127.0.0.1", 5001);
            } else {
                // Creating new API because the global may not be available yet
                const logAPI = new LogApi();
                logAPI.error("Trying to use emulated functions on non-development environment");
            }
        }
    }

    public async getAll(): Promise<Song[]> {
        const user = await this.getCurrentUser();
        const coll = collection(this.db, "users", user.uid, "songs");
        const snapshot = await getDocsFromServer(coll);
        const result: Song[] = [];
        snapshot.forEach((doc): void => {
            const docData = nullToUndefined(doc.data());
            const songData = this.parseFirestoreSong(doc.id, docData as FirestoreSong);
            result.push(songData);
        });

        return result;
    }

    public async get(songId: string, uid?: string): Promise<Song | undefined> {
        const docRef = await this.getSongDocRef(songId, uid);
        const docSnap = await getDocFromServer(docRef);

        if (!docSnap.exists()) {
            return undefined;
        }

        const data = nullToUndefined(docSnap.data()) as FirestoreSong;
        return this.parseFirestoreSong(songId, data);
    }

    public async delete(id: string): Promise<void> {
        const docRef = await this.getSongDocRef(id);
        await deleteDoc(docRef);
    }

    public async set(songId: string, song: Partial<SongDTO>): Promise<Song> {
        const songWithoutExtraFields = omitFields(song, ["id"]);
        songWithoutExtraFields.version = APP_VERSION;
        const setSong = httpsCallable(this.functions, "setSong");
        const result = await setSong({
            song: {
                ...songWithoutExtraFields,
                id: songId,
            },
        });
        const rawSong = result.data as FirebaseSong;
        return this.parseFirestoreSong(rawSong.id, rawSong);
    }

    public async updateVisibility(songId: string, visibility: SongVisibility): Promise<void> {
        const updateVisibility = httpsCallable(this.functions, "updateVisibility");
        await updateVisibility({
            visibility,
            songId,
        });
    }

    /** Returns current user, throws if user is not available */
    private async getCurrentUser(): Promise<User> {
        const user = await this.auth.getCurrentUser();
        if (!user) throw new Error("User not available");
        return user;
    }

    private normalizeTimestamp(timestamp: Timestamp | number): number {
        if (typeof timestamp === "number") {
            return timestamp;
        }
        return timestamp.toMillis();
    }

    private parseFirestoreSong(songId: string, originalSong: FirestoreSong): Song {
        try {
            if (!originalSong.metadata.createdAt || !originalSong.metadata.lastEditTime) {
                api.log.error(new Error(`Song ${songId} does not has time metadata`));
            }

            const metadata = {
                ...originalSong.metadata,
                createdAt: this.normalizeTimestamp(originalSong.metadata.createdAt ?? Date.now()),
                lastEditTime: this.normalizeTimestamp(originalSong.metadata.lastEditTime ?? Date.now()),
            };

            const originalSongDTO = {
                ...originalSong,
                metadata,
            };

            return parseSongDTO(songId, originalSongDTO);
        } catch (err) {
            if (!(err instanceof SongParseError)) {
                console.error("Unknown error parsing Song", songId, err);
            }
            throw err;
        }
    }

    private async getSongDocRef(songId: string, uid?: string): Promise<DocumentReference> {
        let pathUID: string;
        if (!uid) {
            const user = await this.getCurrentUser();
            pathUID = user.uid;
        } else {
            pathUID = uid;
        }

        return doc(this.db, "users", pathUID, "songs", songId);
    }
}
