<template>
    <div
        class="keyboard-container"
        :class="{ 'show-collapse-toggle': showCollapseToggle, collapsed: isCollapsed }"
        ref="keyboardContainer">
        <suggestions-overlay
            :hide="hideSuggestions || isCollapsed || lastFret === undefined"
            :fret="lastFret"
            @on-click="onSuggestionsClick" />
        <div v-if="showCollapseToggle" class="collapse-toggle" @click="onToggleCollapsed">
            <i :class="{ up: isCollapsed }" class="bi bi-arrow-down-circle-fill"></i>
        </div>
        <div class="simple-keyboard"></div>
    </div>
</template>

<script lang="ts">
import Keyboard from "simple-keyboard";
import { defineComponent, PropType } from "vue";
import type { Fret } from "../../../common/note";
import { isMobileUser } from "../../utils/is-mobile-user";
import { KEYS } from "./keys";
import SuggestionsOverlay from "./suggestions-overlay.vue";

type KeyboardLayout = "default" | "shift" | "markings";

export default defineComponent({
    name: "keyboard",
    emits: {
        keyPress: (_button: string) => true,
        applySuggestion: (_button: string) => true,
    },
    props: {
        hideSuggestions: {
            type: Boolean as PropType<boolean>,
        },
        lastFret: {} as { type: PropType<Fret> },
        disableModifiers: {
            type: Boolean as PropType<boolean>,
        },
    },
    data: () => ({
        keyboard: undefined as Keyboard | undefined,

        isCollapsed: true,
        isTemporarilyExpanded: false, // Used at `handleMarkingsShift` to remember if the keyboard was expanded or not at .
        isKeyboardShift: false,

        resizeBind: undefined as any, // Intermediary bind used to properly remove event.
        resizeObserver: undefined as ResizeObserver | undefined,

        firstRowHeight: 40,
        keyboardTransitionDuration: 200,
    }),
    components: {
        "suggestions-overlay": SuggestionsOverlay,
    },
    watch: {
        disableModifiers: {
            immediate: true,
            handler(_newValue, _oldValue) {
                this.updateModifiersState();
            },
        },
    },
    mounted(): void {
        this.isCollapsed = !isMobileUser();

        const shortcutKeys = `{${KEYS.HAMMER_PULL}} {${KEYS.SLIDE}} {${KEYS.BEND}} {${KEYS.VIBRATO}} {${KEYS.BAR.LINE}}`;
        const actionKeys = `{Cut} {Copy} {Paste} {Space} {Undo} {Redo} {Enter}`;

        this.keyboard = new Keyboard("simple-keyboard", {
            onKeyPress: this.onKeyPress,
            maxLength: 20, // This needs to be at least as long as the longest input value
            layout: {
                default: [
                    `{MarkingsShift} ${shortcutKeys}`,
                    "0 1 2 3 4 5 6 7",
                    "{Shift} 8 9 10 11 12 X {Backspace}",
                    actionKeys,
                ],
                shift: [
                    `{MarkingsShift} ${shortcutKeys}`,
                    "13 14 15 16 17 18 19 20",
                    "{Shift} 21 22 23 24 25 X {Backspace}",
                    actionKeys,
                ],
                markings: [
                    `{MarkingsShiftBack} ${shortcutKeys}`,
                    `{${KEYS.BAR.LINE}} {${KEYS.BAR.DOUBLE}} {${KEYS.BAR.REPEAT}} {${KEYS.UPSTROKE}} {${KEYS.DOWNSTROKE}}`,
                    `{${KEYS.HARMONIC}} {${KEYS.HAMMER_PULL}} {${KEYS.SLIDE}} {${KEYS.VIBRATO}} {${KEYS.BEND}} {${KEYS.BEND_DOWN}} {${KEYS.PRE_BEND}}`,
                    actionKeys,
                ],
            } satisfies Record<KeyboardLayout, string[]>,
            display: {
                "{Backspace}": `<i class="bi bi-backspace-fill"></i>`,
                "{Enter}": `<i class="bi bi-arrow-return-left"></i>`,
                "{Shift}": `<i class="bi bi-shift-fill"></i>`,
                "{Cut}": `<i class="bi bi-scissors"></i><span class="name">${this.$t("cut")}</span>`,
                "{Copy}": `<i class="bi bi-files"></i><span class="name">${this.$t("copy")}</span>`,
                "{Paste}": `<i class="bi bi-clipboard"></i><span class="name">${this.$t("paste")}</span>`,
                "{Space}": " ",
                "{Undo}": `<i class="bi bi-arrow-counterclockwise"></i><span class="name">${this.$t("undo")}</span>`,
                "{Redo}": `<i class="bi bi-arrow-clockwise"></i><span class="name">${this.$t("redo")}</span>`,
                "{MarkingsShift}": `<i class="bi bi-plus-lg"></i>`,
                "{MarkingsShiftBack}": `<i class="bi bi-plus-lg"></i>`,
                [`{${KEYS.BAR.LINE}}`]: this.createMarkingKey("bar-line", this.$t("bar")),
                [`{${KEYS.BAR.DOUBLE}}`]: this.createMarkingKey("double-bar-line", this.$t("doubleBar")),
                [`{${KEYS.BAR.REPEAT}}`]: this.createMarkingKey("repeat-bar-line", this.$t("repeatBar")),
                [`{${KEYS.HAMMER_PULL}}`]: this.createMarkingKey("hp-key", this.$t("hammerPull")),
                [`{${KEYS.SLIDE}}`]: this.createMarkingKey("slide-key", this.$t("slide")),
                [`{${KEYS.BEND}}`]: this.createMarkingKey("bend-key", this.$t("bend")),
                [`{${KEYS.PRE_BEND}}`]: this.createMarkingKey("pre-bend-key", this.$t("preBend")),
                [`{${KEYS.BEND_DOWN}}`]: this.createMarkingKey("bend-down-key", this.$t("bendDown")),
                [`{${KEYS.VIBRATO}}`]: this.createMarkingKey("vibrato-key", this.$t("vibrato")),
                [`{${KEYS.DOWNSTROKE}}`]: this.createMarkingKey("downstroke-key", this.$t("downstroke")),
                [`{${KEYS.HARMONIC}}`]: this.createMarkingKey("harmonics-key", this.$t("harmonics")),
                [`{${KEYS.UPSTROKE}}`]: this.createMarkingKey("upstroke-key", this.$t("upstroke")),
                [`{${KEYS.TREMOLO_PICKING}}`]: this.createMarkingKey("tremolo-key", this.$t("tremoloPicking")),
            },
        });
        this.updateModifiersState();
        this.initializeClipboardState();

        this.resizeBind = this.onResize;
        this.resizeObserver = new ResizeObserver(this.resizeBind);
        this.resizeObserver.observe(this.keyboardContainer);
    },
    unmounted(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
    computed: {
        keyboardContainer(): HTMLElement {
            return this.$refs.keyboardContainer as HTMLElement;
        },
        showCollapseToggle(): boolean {
            return !isMobileUser();
        },
        collaseToggleHeight(): string {
            return `${this.firstRowHeight + 1}px`;
        },
        collapsedTransform(): string {
            return `translateY(calc(100% - ${this.firstRowHeight}px))`;
        },
        keyboardTransitionDurationStyleValue(): string {
            return `${this.keyboardTransitionDuration / 1000}s`;
        },
    },
    methods: {
        onKeyPress(button: string): void {
            const formattedButton = button.replace(/[\{\}]/g, ""); // Remove { } if there where any

            if (formattedButton === "Shift" || formattedButton === "Lock") return this.handleShift();
            if (formattedButton === "MarkingsShift" || formattedButton === "MarkingsShiftBack") {
                return this.handleMarkingsShift();
            }

            this.$emit("keyPress", formattedButton);
        },
        onSuggestionsClick(suggestion: string): void {
            this.$emit("applySuggestion", suggestion);
        },
        handleShift(): void {
            if (!this.keyboard) throw new Error("Error: Keyboard not available");
            this.isKeyboardShift = !this.isKeyboardShift;

            const layout = this.isKeyboardShift ? "shift" : "default";
            this.updateKeyboardLayout(layout);
        },
        handleMarkingsShift(): void {
            if (!this.keyboard) throw new Error("Error: Keyboard not available");
            const currentLayout = this.keyboard.options.layoutName;

            if (this.isCollapsed) {
                // On a collapsed keyboard, pressing the markings button should always
                // show the markings layout, temporarily expanding the keyboard.
                this.isCollapsed = false;
                this.isTemporarilyExpanded = true;
                this.updateKeyboardLayout("markings");
                return;
            }

            // On an expanded keyboard, while not being on the markings layout, pressing
            // the markings button should go to the markings layout.
            if (currentLayout !== "markings") {
                this.updateKeyboardLayout("markings");
                return;
            }

            // On an expanded keyboard, while being on the markings lyout, pressing
            // the markings button should go back to the previous numeric marking layout.
            const previousLayout = this.isKeyboardShift ? "shift" : "default";

            // As a UX improvement we want to delay the markings layout switching until
            // after the keyboard collapse animation has finished.
            const layoutChangeDelay = this.isTemporarilyExpanded ? this.keyboardTransitionDuration : 0;

            // If the keyboard was temporarily expanded, we want to automatically collapse it.
            if (this.isTemporarilyExpanded) {
                this.isCollapsed = true;
                this.isTemporarilyExpanded = false;
            }

            setTimeout(() => {
                this.updateKeyboardLayout(previousLayout);
            }, layoutChangeDelay);
        },
        updateKeyboardLayout(layout: KeyboardLayout): void {
            if (!this.keyboard) throw new Error("Error: Keyboard not available");

            this.keyboard.setOptions({
                layoutName: layout,
            });
            this.updateModifiersState();
        },
        updateModifiersState(): void {
            if (!this.keyboard) {
                return;
            }

            const modKeys = [KEYS.HAMMER_PULL, KEYS.SLIDE, KEYS.BEND, KEYS.BEND_DOWN, KEYS.PRE_BEND, KEYS.HARMONIC];

            const modifierButtons = modKeys.flatMap((modNoteName) => {
                return this.keyboard?.getButtonElement(`{${modNoteName}}`) ?? [];
            });

            modifierButtons.forEach((modifierButton) => {
                if (this.disableModifiers) {
                    modifierButton.classList.add("disabled");
                } else {
                    modifierButton.classList.remove("disabled");
                }
            });
        },
        initializeClipboardState(): void {
            if (this.$store.clipboard.isSupported) {
                return;
            }

            const copyButton = this.keyboard?.getButtonElement("{Copy}") as HTMLElement;
            const pasteButton = this.keyboard?.getButtonElement("{Paste}") as HTMLElement;
            const cutButton = this.keyboard?.getButtonElement("{Cut}") as HTMLElement;

            copyButton.classList.add("unsupported");
            copyButton.setAttribute("title", this.$store.clipboard.UNSUPPORTED_MESSAGE);
            pasteButton.classList.add("unsupported");
            pasteButton.setAttribute("title", this.$store.clipboard.UNSUPPORTED_MESSAGE);
            cutButton.classList.add("unsupported");
            cutButton.setAttribute("title", this.$store.clipboard.UNSUPPORTED_MESSAGE);
        },
        onToggleCollapsed(): void {
            this.isCollapsed = !this.isCollapsed;
            this.isTemporarilyExpanded = false;
        },
        onResize(): void {
            // Note: Using a computed property to get the keyboard height would cache the initial value
            const keyboardFirstRow = this.keyboardContainer.querySelector(".hg-row") as HTMLElement;
            this.firstRowHeight = keyboardFirstRow.clientHeight;
        },
        createMarkingKey(cls: string, label: string): string {
            return `<img class="${cls}"><span class="name">${label}</span>`;
        },
    },
});
</script>

<style lang="scss" scoped>
// @use "../../../node_modules/simple-keyboard/build/css/index.css";
.keyboard-container {
    position: fixed;
    bottom: 0;
    z-index: 10;

    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    transform: translateY(0);
    transition: all v-bind("keyboardTransitionDurationStyleValue") ease-in-out;

    max-width: 1000px;
    width: 100%;

    &.hidden {
        transform: translateY(100%);
    }

    &:not(.hidden) {
        &.collapsed {
            transform: v-bind("collapsedTransform");
        }
    }

    .collapse-toggle {
        align-items: center;
        background-color: var(--keyboard-background-color);
        border-bottom: 1px solid var(--keyboard-background-color-dark);
        border-top-right-radius: 5px;
        display: flex;
        height: v-bind("collaseToggleHeight");
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        z-index: 30;
        cursor: pointer;

        &:hover {
            background-color: 1px solid var(--keyboard-background-color-dark);
        }

        i {
            &::before {
                transition: all 0.2s ease-in-out 0.2s;
                transform: rotate(0deg);
            }
            &.up {
                &::before {
                    transform: rotate(-180deg);
                }
            }
        }
    }
}
</style>

<style lang="scss">
.keyboard-container {
    &.show-collapse-toggle {
        .hg-row {
            &:first-child {
                margin-right: 50px;
            }
        }
    }

    .simple-keyboard {
        &.hg-theme-default {
            background-color: var(--keyboard-background-color);
            box-shadow: var(--shadow-sm);
            padding: 0;
            position: relative;
            z-index: 20;

            .hg-row {
                &:first-child {
                    background: var(--keyboard-primary-color);

                    &:not(:last-child) {
                        border-bottom: 1px solid var(--keyboard-primary-dark-color);
                    }

                    .hg-button {
                        height: 40px;
                        overflow: hidden;
                        width: 78px;

                        &:first-child {
                            width: 50px;
                            max-width: 50px;
                        }

                        &:not(:last-child) {
                            border-right: 1px solid var(--keyboard-primary-dark-color);
                        }

                        img {
                            height: 18px;
                        }

                        &.disabled {
                            background-color: var(--keyboard-primary-disabled-color);
                        }
                    }
                }

                /* Uncomment to enable displaying modifiers in multiple rows
                @media (max-width: 576px) {
                    &:first-child {
                        flex-wrap: wrap;

                        .hg-button {
                            flex-basis: 20%;
                        }
                    }
                }
                */

                &:not(:last-child) {
                    border-bottom: 1px solid var(--keyboard-background-color-dark);
                    margin-bottom: 0;
                }

                .hg-button {
                    border-radius: 0;
                    background: transparent;
                    border-bottom: none;
                    box-shadow: none;
                    height: 52px;

                    &.hg-button-Space {
                        flex-grow: 5;
                    }

                    &:not(:last-child) {
                        margin-right: 0;
                        border-right: 1px solid var(--keyboard-background-color-dark);
                    }

                    &:hover {
                        background: rgba(0, 0, 0, 0.03);
                        cursor: pointer;
                    }

                    &:active {
                        background: rgba(0, 0, 0, 0.1);
                    }

                    &.disabled {
                        pointer-events: none;
                        > * {
                            opacity: 0.4;
                        }
                    }

                    &.unsupported {
                        cursor: not-allowed;

                        > * {
                            opacity: 0.3;
                        }
                    }

                    > span {
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        max-width: 100%;

                        .name {
                            font-size: 0.6rem;
                            max-width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-wrap: nowrap;
                        }
                    }
                }
            }
        }
    }
}
</style>
