import { initializeApp } from "firebase/app";
import { AuthApi } from "./auth-api";
import { ApiConfig } from "./config";
import { CookieApi } from "./cookies";
import { LocalSongsApi } from "./local-songs-api";
import { LocalStorage } from "./local-storage";
import { LogApi } from "./log";
import { PrintAPI } from "./pdf";
import { RemoteSongsApi } from "./remote-songs-api";
import { SongSync } from "./song-sync";
import { SongsApi } from "./songs-api";

const firebase = initializeApp(ApiConfig.firebaseConfig);
const cookiesApi = new CookieApi();

const authApi = new AuthApi(firebase, cookiesApi);

const localSongsApi = new LocalSongsApi();
const remoteSongsApi = new RemoteSongsApi(authApi, firebase);
const songsApi = new SongsApi(localSongsApi, remoteSongsApi);

const syncApi = new SongSync({
    songsApi: songsApi,
    remote: remoteSongsApi,
    local: localSongsApi,
});

export const api = {
    songs: songsApi,
    auth: authApi,
    localStorage: new LocalStorage(),
    log: new LogApi(),
    syncApi: syncApi,
    cookies: cookiesApi,
    print: new PrintAPI(),
};
