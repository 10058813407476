import type { SongActionOptions, SongActionsType } from "../interfaces/song-actions";
import type { Song } from "../models/song/song";
import { getEditorStore } from "../stores/editor.store";

export function shouldHideAction(action: SongActionsType, song: Song, options: SongActionOptions): boolean {
    const editorStore = getEditorStore();
    if (editorStore.isNewSong) {
        return true;
    }

    if (action === "archive" && song.isInTrash()) {
        return true;
    }

    if (action === "delete" && !song.isInTrash()) {
        return true;
    }

    if (action === "edit" && !song.isEditable()) {
        return true;
    }

    if (action === "pin" && (song.isArchived() || song.isInTrash())) {
        return true;
    }

    if (action === "share" && song.isInTrash()) {
        return true;
    }

    if (action === "copy" && song.isInTrash()) {
        return true;
    }

    if (action === "moveToNotebook" && (song.isArchived() || song.isInTrash())) {
        return true;
    }

    if (action === "exportAscii" && song.isInTrash()) {
        return true;
    }

    return options.hide === true;
}
