import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import type { PiniaPluginContext } from "pinia";
import { createPinia } from "pinia";
import { registerSW } from "virtual:pwa-register";
import { createApp } from "vue";
import { api } from "./apis/api";
import { ApiConfig } from "./apis/config";
import App from "./app.vue";
import { bus } from "./bus";
import { hideOnScrollDirective } from "./directives/hide-on-scroll/hide-on-scroll";
import { i18n, i18nTranslate } from "./i18n";
import { getRouter } from "./router/router";
import { getClipboardStore } from "./stores/clipboard.store";
import { getConfigStore } from "./stores/config.store";
import { getEditorStore } from "./stores/editor.store";
import { getInstallStore } from "./stores/install.store";
import { getSongStore } from "./stores/song.store";
import { getUserStore } from "./stores/user.store";
import { getZoomStore } from "./stores/zoom.store";

const app = createApp(App);
/// Setup Bugsnag
Bugsnag.start({
    apiKey: "e7d80b6feb0e37c41ce4e9bc868566b7",
    plugins: [new BugsnagPluginVue()],
    collectUserIp: false,
    autoTrackSessions: false,
    releaseStage: ApiConfig.releaseStage,
    enabledReleaseStages: ["production", "staging"],
    appVersion: APP_VERSION,
    onError: function (_event) {},
});
const bugsnagVue = Bugsnag.getPlugin("vue");
if (bugsnagVue) {
    app.use(bugsnagVue);
} else {
    api.log.error("Bugsnag Vue not available");
}

app.use(i18n);

/// Setup Pinia
const myPlugin = ({ store }: PiniaPluginContext) => {
    store.$bus = bus;
    store.$t = i18nTranslate;
};
const pinia = createPinia();
pinia.use(myPlugin);
app.use(pinia);

const userStore = getUserStore();

// Setup ServiceWorker
// const updateCheckInterval = 10 * 60 * 1000;
userStore.swReload = registerSW({
    // onRegistered(r) {
    //     r &&
    //         setInterval(() => {
    //             // Check if service worker should be updated
    //             r.update();
    //         }, updateCheckInterval);
    // },
    onNeedRefresh() {
        userStore.needReload = true;
    },
});

/// Setup Global properties
app.config.globalProperties.$bus = bus;
app.config.globalProperties.$store = {
    clipboard: getClipboardStore(),
    config: getConfigStore(),
    editor: getEditorStore(),
    install: getInstallStore(),
    song: getSongStore(),
    user: getUserStore(),
    zoom: getZoomStore(),
};

/// Setup Router
const router = getRouter();
app.use(router);

/// Setup Directives
app.directive("hide-on-scroll", hideOnScrollDirective);

/// Mount app
app.mount("#app");
