<template>
    <li
        v-if="!hide"
        class="btn"
        :class="{ disabled }"
        :title="tooltip"
        @click.prevent="onPrintPdf"
        @keydown.enter.prevent="onPrintPdf"
        tabindex="0">
        <i class="bi bi-filetype-pdf"></i>
        <span v-if="!hideText">{{ text }}</span>
    </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { api } from "../../../../apis/api";
import { SongActionOptions } from "../../../../interfaces/song-actions";
import { Song } from "../../../../models/song/song";
import { shouldHideAction } from "../../../../utils/song-actions-utils";

export default defineComponent({
    name: "song-action-export-pdf",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        songActionOptions: {
            type: Object as PropType<SongActionOptions>,
            default: () => ({}),
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            actionInProgress: false,
        };
    },
    computed: {
        hide(): boolean {
            return shouldHideAction("exportPdf", this.song, this.songActionOptions);
        },
        disabled(): boolean {
            return this.actionInProgress || this.songActionOptions.disabled === true;
        },
        text(): string {
            return this.$t("actionExportPdf");
        },
        tooltip(): string {
            return this.text;
        },
    },
    methods: {
        async onPrintPdf(): Promise<void> {
            if (api.auth.isIOSApp) {
                this.$bus.emit("error", this.$t("error/exportPDFInIOS"));
                return;
            }

            if (!api.print.canPrint()) {
                this.$bus.emit("error", this.$t("error/unsupportedPrint"));
                api.log.error("Print is not supported: window.print is undefined");
            } else {
                api.log.track("exportPdf");
                this.$router.push({
                    name: "pdf",
                    query: { song: this.song.id },
                });
            }
        },
    },
});
</script>
