import { SongStatus } from "@common/song-status";
import Joi from "joi";
import { defineStore } from "pinia";
import { CURRENT_SONG_DTO_VERSION, DEFAULT_TUNING, LoadingStatus } from "../../common/constants";
import type { Fret } from "../../common/note";
import { DEFAULT_VISIBILITY } from "../../common/song-metadata";
import { api } from "../apis/api";
import { isApiError } from "../apis/errors";
import type { TablaturePosition } from "../interfaces/tablature-position";
import { Song } from "../models/song/song";
import type { ParserInput } from "../models/song/song-parser";
import { parseSongDTO } from "../models/song/song-parser";
import { serializeSong } from "../models/song/song-serializer";
import { EditorSelection } from "../services/editor-selection/editor-selection";
import { getUID } from "../utils/uuid";
import { getConfigStore } from "./config.store";

export const getEditorStore = defineStore("editor", {
    state() {
        return {
            selection: new EditorSelection(),
            selectedSong: undefined as Song | undefined,
            scrollPosition: 0,
            lastModifiedNote: undefined as
                | {
                      note: Fret;
                      position: TablaturePosition;
                  }
                | undefined,
            showSuggestions: false, // This is only a flag to avoid showing suggestions on some cases
            hasSongChanged: false,
            isNewSong: false,
            loadingStatus: LoadingStatus.LOADING,
        };
    },
    getters: {
        selectedSongId(): string | undefined {
            return this.selectedSong?.id;
        },
    },
    actions: {
        async loadSharedSong(songId: string, uid: string): Promise<void> {
            if (songId !== this.selectedSongId) {
                this.scrollPosition = 0;
            }

            this.loadingStatus = LoadingStatus.LOADING;
            this.clearSelectedSong();
            try {
                const song = await api.songs.getShared(songId, uid);
                this.setSelectedSong(song);
                this.loadingStatus = LoadingStatus.READY;
            } catch (error: unknown) {
                console.error(error);
                this.loadingStatus = LoadingStatus.ERROR;
                if (isApiError(error) && error.name === "song-not-found") {
                    return;
                }
                api.log.error(error, "Error loading song");
            }
        },
        async loadSong(songId: string): Promise<void> {
            if (songId !== this.selectedSongId) {
                this.scrollPosition = 0;
            }

            this.loadingStatus = LoadingStatus.LOADING;
            this.clearSelectedSong();
            try {
                const song = await api.songs.get(songId);
                this.setSelectedSong(song);
                // this.isNewSong = uid !== undefined;
                this.loadingStatus = LoadingStatus.READY;
            } catch (error: unknown) {
                console.error(error);
                this.loadingStatus = LoadingStatus.ERROR;
                if (isApiError(error) && error.name === "song-not-found") {
                    return;
                }
                api.log.error(error, "Error loading song");
            }
        },
        async createNewLocalSong(): Promise<void> {
            this.clearSelectedSong();

            const newSong = this.getDefaultSong();

            this.setSelectedSong(newSong);
            this.isNewSong = true;
            this.loadingStatus = LoadingStatus.READY;
        },
        async saveSong(): Promise<void> {
            const song = this.selectedSong as Song;
            try {
                const songDTO = serializeSong(song);
                songDTO.version = APP_VERSION;

                const timestamp = Date.now();
                songDTO.metadata.lastEditTime = timestamp;
                if (this.isNewSong) {
                    const configStore = getConfigStore();
                    if (configStore.selectedNotebook) {
                        songDTO.metadata.labels = [configStore.selectedNotebook];
                    } else {
                        songDTO.metadata.labels = [];
                    }
                    songDTO.metadata.createdAt = timestamp;
                }

                let songId: string;
                if (this.isNewSong) {
                    songId = getUID();
                } else {
                    songId = song.id;
                }

                await api.songs.setSong(songId, songDTO);
                const newSong = new Song({ id: songId, ...songDTO });

                this.setSelectedSong(newSong);
                this.hasSongChanged = false;

                if (this.isNewSong) {
                    this.isNewSong = false;
                }
            } catch (error: unknown) {
                console.error(error);
                if (error instanceof Joi.ValidationError) {
                    this.$bus.emit("error", `Song could not be saved: ${error.message}`);
                } else {
                    this.$bus.emit("error", "Song could not be saved");
                }
                api.log.error(error, "Error saving song");
                throw error;
            }
        },
        async copySharedSong(): Promise<string> {
            const song = this.selectedSong as Song;
            try {
                const songDTO = song.sanitize().toSharedSongDTO();

                const newSong = new Song({ ...this.getDefaultSong(), ...songDTO });
                const songId = getUID();
                await api.songs.setSong(songId, newSong.toDTO());
                return songId;
            } catch (error: unknown) {
                this.$bus.emit("error", "Song could not be saved");
                throw error;
            }
        },
        async copySong(song: Song): Promise<void> {
            try {
                const songDTO = serializeSong(song);

                const timestamp = Date.now();
                songDTO.metadata.lastEditTime = timestamp;
                songDTO.metadata.createdAt = timestamp;

                const newSong = new Song({ ...this.getDefaultSong(), ...songDTO });
                const songId = getUID();
                await api.songs.setSong(songId, newSong.toDTO());
            } catch (error: unknown) {
                this.$bus.emit("error", "Song could not be copied");
                throw error;
            }
        },
        clearSelectedSong(): void {
            this.selectedSong = undefined;
            this.clearSelection();
            this.lastModifiedNote = undefined;
            this.hasSongChanged = false;
        },
        setSelectedSong(song: Song): void {
            this.selectedSong = song;
            this.selection.setSongStrings(song.tablature.lineCount);

            this.lastModifiedNote = undefined;
            this.showSuggestions = false;
            this.hasSongChanged = false;
        },
        clearSelection() {
            this.selection.clear();
        },
        updateStatus(status: SongStatus) {
            if (!this.selectedSong) {
                return;
            }
            this.selectedSong.status = status;
        },
        markAsChanged(): void {
            this.hasSongChanged = true;
        },
        getDefaultSong(): Song {
            const timestamp = Date.now();

            const newSong: ParserInput = {
                title: "",
                tags: {
                    instrument: "guitar",
                },
                tablature: {
                    events: [],
                    stave: {
                        lineCount: 6,
                    },
                },
                status: SongStatus.Default,
                metadata: {
                    lastEditTime: timestamp,
                    createdAt: timestamp,
                    visibility: DEFAULT_VISIBILITY,
                    labels: [],
                },
                tuning: DEFAULT_TUNING,
                _v: CURRENT_SONG_DTO_VERSION,
            };

            return parseSongDTO("", newSong);
        },
    },
});

export type EditorStore = ReturnType<typeof getEditorStore>;
