import de from "./locales/de.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
import fil from "./locales/fil.json";
import fr from "./locales/fr.json";
import hi from "./locales/hi.json";
import id from "./locales/id.json";
import it from "./locales/it.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import nl from "./locales/nl.json";
import pl from "./locales/pl.json";
import pt from "./locales/pt.json";
import ru from "./locales/ru.json";
import sv from "./locales/sv.json";
import tr from "./locales/tr.json";
import zh from "./locales/zh.json";

import { createI18n } from "vue-i18n";
import { api } from "./apis/api";

// Setup Localization
const locale = api.localStorage.get("userLocale") ?? navigator.language.split("-")[0] ?? "en";

type Locales =
    | "de"
    | "en"
    | "es"
    | "fil"
    | "fr"
    | "hi"
    | "id"
    | "it"
    | "ja"
    | "ko"
    | "nl"
    | "pl"
    | "pt"
    | "ru"
    | "sv"
    | "tr"
    | "zh";
type TranslationSchema = typeof en;

// If types fail in messages, you probably missed a translation in locales/*.json
export const i18n = createI18n<[TranslationSchema], Locales>({
    legacy: false,
    locale,
    fallbackLocale: "en",
    messages: {
        de,
        en,
        es,
        fil,
        fr,
        hi,
        id,
        it,
        ja,
        ko,
        nl,
        pl,
        pt,
        ru,
        sv,
        tr,
        zh,
    },
});

export const i18nTranslate = (...args: Parameters<typeof i18n.global.t>) => i18n.global.t(...args);
