import Bugsnag from "@bugsnag/js";

export type TrackEvent =
    | "togglePin"
    | "toggleArchive"
    | "toggleTrash"
    | "copySharedSong"
    | "shareSong"
    | "copySong"
    | "moveToNotebook"
    | "renameNotebook"
    | "deleteNotebook"
    | "updateInstrument"
    | "generateAscii"
    | "exportPdf";

export class LogApi {
    /** Report an error to bugsnag as a handled error */
    public error(error: unknown, extraMessage?: string): void {
        if (error instanceof Error) {
            Bugsnag.notify(error);
            console.error(error);
        } else if (extraMessage) {
            Bugsnag.notify(`${extraMessage} ${error}`);
            console.error(`${extraMessage} ${error}`);
        } else {
            Bugsnag.notify(`${error}`);
            console.error(`${error}`);
        }
    }

    /** Track event to umami */
    public track(event: TrackEvent): void {
        if (window.umami && umami) {
            umami.track(event);
        }
    }

    /** Track version as an event to umami */
    public trackVersion(): void {
        if (window.umami && umami && APP_VERSION) {
            umami.track(`v${APP_VERSION}`);
        }
    }
}
