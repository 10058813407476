export class PrintAPI {
    public canPrint(): boolean {
        return Boolean(window.print);
    }

    /** Note: This is a synchronous call to browsers apis */
    public print(): void {
        if (!this.canPrint) {
            throw new Error("Can't print, api is not available in browser");
        }
        window.print();
    }
}
